.customSelect__wrapper .customSelect__control {
  @apply border-secondary-light w-full rounded border bg-black text-sm leading-tight text-white shadow transition duration-300;
  min-height: 28px;
}
.customSelect__wrapper .customSelect__control:hover {
  @apply border-secondary-light;
}

.customSelect__wrapper .customSelect__control:focus,
.customSelect__wrapper .customSelect__control--menu-is-open {
  @apply border-secondary-light outline-none;
}

.customSelect--is-disabled .customSelect__control--is-disabled {
  @apply pointer-events-none;
}

.css-1hwfws3 {
  padding: 0;
}

.customSelect__wrapper .customSelect__indicator-separator {
  @apply hidden;
}

.customSelect__wrapper .customSelect__dropdown-indicator {
  padding: 4px;
}

.customSelect__wrapper .customSelect__option {
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  @apply flex flex-row items-center;
}

.customSelect__wrapper .customSelect__option--is-selected {
  @apply bg-transparent;
}

.customSelect__wrapper .customSelect__single-value {
  @apply pl-1 text-white;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customSelect__wrapper.customSelect--is-disabled {
  @apply pointer-events-auto cursor-not-allowed;
}

.customSelect__wrapper .customSelect__value-container--is-multi {
  @apply inline-block truncate px-3 py-2;
}

.alm-select .customSelect__menu {
  background-color: transparent !important;
  border-radius: 6px;
}

.alm-select .customSelect__menu-list {
  overflow-x: hidden !important;
  margin: 4px;
  padding-top: 0px !important;
}

.alm-select .customSelect__menu-list::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.alm-select .customSelect__placeholder {
  @apply pl-1.5 text-white;
}

.alm-select .customSelect__menu-list::-webkit-scrollbar-thumb {
  background-color: #008000;
  border-radius: 10px;
}

.customSelect__option--is-focused {
  background-color: #00b300 !important;
}

.alm-select .customSelect__option:hover {
  background-color: #00b300;
}

.alm-select .customSelect__menu {
  border: 2px solid #008000;
  border-radius: 6px;
  background-color: #151515 !important;
}

.css-1hwfws3 {
  all: unset !important;
}
