/* CUSTOM ALM SCROLLBAR */
.alm-scrollbar {
  scrollbar-color: #173239 transparent;
  overflow-y: auto;
}

.alm-scrollbar-stable-gutter {
  scrollbar-gutter: stable;
}

.study-min-height {
  min-height: 450px;
}

.alm-scrollbar:hover {
  overflow-y: auto;
}

.alm-scrollbar::-webkit-scrollbar {
  scrollbar-width: thin;
}

.alm-scrollbar::-webkit-scrollbar-track {
  @apply rounded;
}

.alm-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded;
  @apply bg-secondary-dark;
  background-color: #041c4a;
}

.alm-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  @apply bg-secondary-dark;
  background-color: #041c4a;
}

/* INVISIBLE SCROLLBAR */
.invisible-scrollbar {
  scrollbar-width: none;
}

.invisible-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-track {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-thumb {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  @apply hidden;
}
